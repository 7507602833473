.custom-tabs .ant-tabs-nav {
    margin-bottom: 0;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e8e8e8;
  }
  
  .custom-tabs .ant-tabs-tab {
    margin-right: 0;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    border: none;
    background-color: #f5f5f5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .custom-tabs .ant-tabs-tab-active {
    color: #1890ff;
    background-color: #fff;
    border-color: #e8e8e8 #e8e8e8 #fff;
  }

  td, th {
    font-weight: bold!important;
  }