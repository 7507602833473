.notice-board {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f2f2f2;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .notice-header {
    height: 40px;
    background-color: #337ab7;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .notice-content {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
  }
  
  .notice-content p {
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #ccc;
  }
  