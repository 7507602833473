/* All customised styles are here */

a{
    color: rgb(109,140,125) !important;
}

a:hover{
    color: #1890ff !important;
}

.ant-spin-dot-item {
    background-color: white;
}

.MuiIconButton-root {
    background-color: inherit !important;
}

.MuiTableRow-root:not(:first-of-type):hover,
.MuiTableRow-root:not(:first-of-type)[mode='add'],
.MuiTableRow-root:not(:first-of-type)[mode='update'] {
    background-color: #dcdcdc52;
}

body{
    font-size:14px ;
}

h1{
    font-size: 40px ;
}
h2{
    font-size: 34px ;
}
h3{
    font-size: 28px ;
}
h4{
    font-size: 24px ;
}
h5{
    font-size: 18px ;
}
.jadcup-form-list .ant-row-middle {
    align-items: baseline;
}
.jadcup-form-list .ant-form-item {
    margin-bottom: 0.5rem;
}
.jadcup-form-list .ant-divider-horizontal {
    margin: 1rem 0;
}

.affiche{
    color: red;
    display: block;
    width: 100%;
    height: 60px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    text-align:left;
    line-height: 60px;
}
.affiche_text{
    position: absolute;
    top: 0;
    left: 100%;
    line-height: 60px;
    display: inline-block;
    word-break: keep-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 3rem;
}

.img-preview .ant-upload-list-picture-card-container {
    width: 80%;
    height: 90%;
  }
  
