.customer .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.customer .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}
.swal-input-container {
  margin-bottom: -5px; /* 添加垂直间距，将label和input分开 */
}
/* 设置input的宽度 */
.swal-input-container input{
  width: 350px; /* 或者你可以设置一个固定的宽度，例如: width: 300px; */
}
.swal-input-container textarea{
  width: 350px; /* 或者你可以设置一个固定的宽度，例如: width: 300px; */
}