.loginImage{
    background-image:url('https://static.wixstatic.com/media/6f5302_f2a9a4a2fa0a49c19edfd0e4cee6bdd2~mv2.jpg/v1/fill/w_1440,h_821,al_c,q_85,usm_0.66_1.00_0.01/6f5302_f2a9a4a2fa0a49c19edfd0e4cee6bdd2~mv2.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.cusQuoImage{
    background-image:url('https://jadcup.oss-cn-hongkong.aliyuncs.com/12433bbc-02cd-4d9a-98d0-f26f34084c8c');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.quotation-customer-side .ant-descriptions-item-label,
.quotation-customer-side .ant-descriptions-item-content {
    color: black
}
