.logo {
  width: 5.4rem;
  height: 5.4rem;
  background-image: Url("https://jadcup.oss-cn-hongkong.aliyuncs.com/f8facef4-7757-4adc-99da-99aa90587355");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
  width: 1000px;
  margin: auto;
}

.title {
  margin-bottom: 1rem;
}

.black-block {
  height: 3rem;
  background-color: black;
  width: 100%;
}

.black-button {
  color: white;
  background-color: black;
  border: none;
  padding: 0.4rem;
  width: 14rem;
}

.black-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.grey-button {
  color: black;
  background-color: #d1d1d1;
  border: none;
  padding: 0.4rem;
  width: 14rem;
}

.grey-button:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.white-button {
  color: black;
  background-color: white;
  border: 1px black solid;
  padding: 0.4rem;
  width: 14rem;
}

.white-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.note {
  background-color: #d1d1d1;
  padding: 0.6rem;
}

.quote-terms {
  text-decoration: underline;
  cursor: pointer;
}

.jadcup-link {
  color: #3A9C64;
  text-decoration: underline;
  text-decoration-color: #3A9C64;
}


/* common styles */
.text-white {
  color: white
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.content-between {
  justify-content: space-between;
}

.content-center {
  justify-content: center;
}

.content-start {
  justify-content: flex-start;
}

.content-end {
  justify-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.self-end {
  align-self: flex-end;
}

.width-50 {
  width: 50%;
}

.height-100 {
  height: 100%;
}

.fs-28 {
  font-size: 28px;
}

.hr {
  margin: 1rem 0;
  height: 0;
  border-top: 2px solid #d1d1d1;
}

.black-hr {
  height: 0;
  border-top: 2px solid black;
}

.p-1 {
  padding: 1rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}


